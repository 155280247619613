@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Vina+Sans:wght@400;500;900&display=swap');

* {
  box-sizing: border-box;
}


h1{
font-family: 'Vina Sans', sans-serif;
}

h6{
font-family: 'Poppins', sans-serif;
}


